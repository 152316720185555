import Modal from 'bootstrap/js/src/modal'
import Tooltip from 'bootstrap/js/src/tooltip'

export default ({app}, inject) => {
    inject('modal', el => new Modal(el))
    inject('tooltip', (el, title = '', placement = 'top') => new Tooltip(el,
        {
            title: title,
            placement: placement,
            trigger: 'hover'
        }
    ))
}