import Service from '~/plugins/services/Service'

export default class CheatService extends Service {
    getLast() {
        return this.$axios.$get('/api/cheats/last').then((response) => {
            let posts = []

            if (Array.isArray(response))
                response.forEach((post) => posts.push(post))

            return posts
        })
    }

    getAll() {
        return this.$axios.$get('/api/cheat').then((response) => {
            return response
        })
    }

    sort(cheats) {
        return this.$axios.$patch('/api/cheat/sort', cheats).then(res => {
            return res
        }).catch(() => {
            return false
        })
    }


    create(cheat) {
        return this.$axios.$post('/api/cheat', cheat).then(() => {
            return true
        }).catch(() => {
            return false
        })
    }


    delete(id) {
        return this.$axios.$delete('/api/cheat/' + id).then(() => {
            return true
        }).catch(() => {
            return false
        })
    }


    update(cheat) {
        return this.$axios.$patch('/api/cheat/' + cheat.id, cheat).then(() => {
            return true
        }).catch(() => {
            return false
        })
    }

    view(id) {
        return this.$axios.$get('/api/cheat/' + id).then((cheat) => {
            return cheat
        }).catch(() => {
            return {}
        })
    }

    setOnUpdate(id) {
        return this.$axios.$patch('/api/cheat/set_on_update/' + id).then(() => {
            return true
        }).catch(() => {
            return {}
        })
    }

}