import Service from '~/plugins/services/Service'

export default class GameService extends Service {
    getAll() {
        return this.$axios.$get('/api/game').then((response) => {
            let games = []
            if (Array.isArray(response))
                response.forEach((game) => games.push(game))

            return games
        })
    }

    create(game) {
        return this.$axios.$post('/api/game', game).then(() => {
            return true
        }).catch(() => {
            return false
        })
    }

    view(id) {
        return this.$axios.$get('/api/game/' + id).then((res) => {
            return res
        }).catch(() => {
            return false
        })
    }

    edit(game) {
        return this.$axios.$patch('/api/game/' + game.id, game).then(() => {
            return true
        }).catch(() => {
            return false
        })
    }

    sort(games) {
        return this.$axios.$patch('/api/game/sort', games).then(res => {
            return res
        }).catch(() => {
            return false
        })
    }

    delete(id) {
        return this.$axios.$delete('/api/game/' + id).then(() => {
            return true
        }).catch(() => {
            return false
        })
    }
}