export const state = () => ({
    games: [],
    languages: [],
    settings: {},
    pages: []
})

export const mutations = {
    SET_LANGUAGES(state, languages) {
        if (Array.isArray(languages)) {
            state.languages = languages
        }
    },
    SET_GAMES(state, games) {
        if (Array.isArray(games)) {
            state.games = games
        }
    },
    SET_SETTINGS(state, settings) {
        state.settings = settings
    },
    SET_PAGES(state, pages) {
        state.pages = pages
    },
}

export const getters = {
    getCheatCategories(state) {
        return state.cheat_categories
    },
    getReviews(state) {
        return state.reviews
    },
    getLanguages(state) {
        return state.languages
    },
    getGames(state) {
        return state.games
    },
    getSettings(state) {
        return state.settings
    },
    getPages(state) {
        return state.pages
    },
    getPage: state => (name, locale) => {
        return state.pages.find(i => i.url === name).translates[locale === 'ru' ? 0 : 1];
    }
}

export const actions = {
    async nuxtServerInit({commit, dispatch}) {
        await dispatch('synchronizeLanguagesWithDatabase')
        await dispatch('getGames')
        await dispatch('getSettings')
        await dispatch('getPages')
    },
    async synchronizeLanguagesWithDatabase({commit}) {
        try {
            await this.$axios.get('/api/language')
                .then((res) => {
                    commit('SET_LANGUAGES', res.data)
                })
                .catch((e) => {
                    console.log('Ошибка синхронизации языков с базой данных')
                })
        } catch (e) {
            
        }
    },

    async getGames({commit}) {
        try {
            await this.$axios.get('/api/games')
                .then((res) => {
                    let games = res.data
                    commit('SET_GAMES', games)

                })
                .catch((e) => {
                    console.log('Ошибка загрузки игр', e)
                })
        } catch (e) {
            
        }
       
    },

    async getPages({commit}) {
        try {
            await this.$axios.get('/api/pages')
                .then((res) => {
                    let pages = res.data
                    commit('SET_PAGES', pages)

                })
                .catch((e) => {
                    console.log('Ошибка загрузки страниц', e)
                })
        } catch (e) {

        }

    },

    async getSettings({commit}) {
        try {
            await this.$axios.get('/api/settings')
                .then((res) => {
                    commit('SET_SETTINGS', res.data)
                })
                .catch((e) => {
                    console.log('Ошибка загрузки настроек', e)
                })
        } catch (e) {

        }
    }
}
