import Service from '~/plugins/services/Service'

export default class PageService extends Service {
    getAll() {
        return this.$axios.$get('/api/pages').then((response) => {
            let posts = []
            if (Array.isArray(response))
                response.forEach((post) => posts.push(post))

            return posts
        }).catch(() => {
            return []
        })
    }

    create(post) {
        return this.$axios.$post('/api/pages', post).then(() => {
            return true
        }).catch(() => {
            return false
        })
    }

    view(id) {
        return this.$axios.$get('/api/pages/' + id).then((res) => {
            return res
        }).catch(() => {
            return false
        })
    }

    edit(post) {
        return this.$axios.$patch('/api/pages/' + post.id, post).then(() => {
            return true
        }).catch(() => {
            return false
        })
    }

    delete(id) {
        return this.$axios.$delete('/api/pages/' + id).then(() => {
            return true
        }).catch(() => {
            return false
        })
    }
}