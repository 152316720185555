import BlogService from "@/plugins/services/BlogService";
import GameService from "@/plugins/services/GameService";
import CheatService from "@/plugins/services/CheatService";
import PostService from "@/plugins/services/PostService";
import PageService from "@/plugins/services/PageService";
import CategoryService from "@/plugins/services/CategoryService";
import MediaFileService from "@/plugins/services/MediaFileService";

export default ({app}, inject) => {
    inject('PostService', new PostService(app.$axios))
    inject('PageService', new PageService(app.$axios))
    inject('BlogService', new BlogService(app.$axios))
    inject('GameService', new GameService(app.$axios))
    inject('CheatService', new CheatService(app.$axios))
    inject('CategoryService', new CategoryService(app.$axios))
    inject('MediaFileService', new MediaFileService(app.$axios))
}