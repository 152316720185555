import Service from "@/plugins/services/Service";
import DataHelper from "@/plugins/helpers/DataHelper"

export default class CategoryService extends Service {

    getAll() {
        return this.$axios.$get('/api/categories').then((response) => {
            let categories = []
            if (Array.isArray(response))
                response.forEach((category) => categories.push(category))

            return categories
        })
    }

    create(translates) {
        let postData = DataHelper.convertObserverToDefaultObject(translates);
        return this.$axios.$post('/api/categories', {translates: postData}).then(() => {
            return true
        }).catch(() => {
            return false
        })
    }

    delete(id) {
        return this.$axios.$delete('/api/categories/' + id).then(() => {
            return true
        }).catch(() => {
            return false
        })
    }
}