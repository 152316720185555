import DataHelper from "@/plugins/helpers/DataHelper";
import Service from "@/plugins/services/Service";

export default class BlogService extends Service {

    getAllByPage(page, search = '') {
        let url = '/api/blog';
        let params = new URLSearchParams();

        if (page) {
            params.append('page', page);
        }

        if (search.length) {
            params.append('search', search);
        }

        return this.$axios.$get(url + '?' + params.toString()).then((response) => {
            return response;
        });
    }

    getById(id) {
        return this.$axios.$get('/api/blog/post/' + id).then((response) => {
            let posts = []

            if (Array.isArray(response))
                response.forEach((post) => posts.push(post))

            return posts[0]
        })
    }

    getLastFive() {
        return this.$axios.$get('/api/blog/last').then((response) => {
            let posts = []

            if (Array.isArray(response))
                response.forEach((post) => posts.push(post))

            return posts
        })
    }

}