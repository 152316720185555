import Service from "@/plugins/services/Service";

export default class MediaFileService extends Service {
    create(formData) {
        return this.$axios.$post('/api/files', formData).then((newFile) => {
            return (newFile.hasOwnProperty('id'))
        })
    }

    images(formData) {
        return this.$axios.$get('/api/images', formData).then((response) => {
            let result = []

            if (Array.isArray(response))
                response.forEach((file) => result.push(file))

            return result
        })
    }


    getWithPagination(page_number = 1) {
        return this.$axios.$get('/api/files?page=' + page_number).then((response) => {
            let result = {
                files: [],
                meta: null
            }
            if (Array.isArray(response.data))
                response.data.forEach((file) => result.files.push(file))
            result.meta = response.meta

            return result
        })
    }

    delete(id) {
        return this.$axios.$delete('/api/files/' + id).then(() => {
            return true
        }).catch(() => {
            return false
        })
    }
}