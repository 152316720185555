import Service from '~/plugins/services/Service'

export default class PostService extends Service {
    getAll() {
        return this.$axios.$get('/api/posts').then((response) => {
            let posts = []
            if (Array.isArray(response))
                response.forEach((post) => posts.push(post))

            return posts
        }).catch(() => {
            return []
        })
    }

    create(post) {
        return this.$axios.$post('/api/posts', post).then(() => {
            return true
        }).catch(() => {
            return false
        })
    }

    view(id) {
        return this.$axios.$get('/api/posts/' + id).then((res) => {
            return res
        }).catch(() => {
            return false
        })
    }

    edit(post) {
        return this.$axios.$patch('/api/posts/' + post.id, post).then(() => {
            return true
        }).catch(() => {
            return false
        })
    }

    delete(id) {
        return this.$axios.$delete('/api/posts/' + id).then(() => {
            return true
        }).catch(() => {
            return false
        })
    }
}